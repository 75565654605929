import axios from "axios";
import * as _ from "lodash";

import { ApiType } from "./Types";
import { getUrl } from "./getURL";
import { ConstantMessage } from "../constants/constantsMsg";
import { IDSEntry } from "../models/shortage";

/**
 * CRETE API call using Axios
 * @returns - Update delivered Short Entry and return same object
 */

export const UpdateDSEntryAPI = async (params: IDSEntry, osdNumber?: number | string) => {
  try {
    const response = await axios.put(`${getUrl(ApiType.DS_ENTRY_UPDATE)}/${osdNumber}`, params, {
      headers: {
        "Content-Type": "application/json",
      }
    });
    return response.data;
  } catch (error: any) {
    let message = ConstantMessage.updateDSEntry;
    if (_.get(error, "response.status") === 500) {
      message = ConstantMessage.serverError;
    }else if (_.get(error, "response.status") === 400) {
      message = _.get(error, "errors[0].message") || _.get(error, "response.data.errors[0].message")
    }  
    throw message;
  }
};
