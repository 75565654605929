import { useNavigate } from "react-router-dom";
import AppRoutes from "./components/shared/AppRoutes";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import * as _ from "lodash";
import axios from "axios";
import { useEffect } from "react";
import { resetState } from "./AppState/resetStateActions";
import { useDispatch } from "react-redux";
import Notification from "./components/shared/Notification";
import { isPrivilegedUser, logUserIn } from "./AppState/logUserIn";
import { Log } from "./API/log";
import { validateOktaToken } from "./API/validateOktaToken";

const RootApp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Axios request interceptor
  axios.interceptors.request.use(
    async (request) => {
      const authUser = JSON.parse(localStorage.getItem("authUser")!);
      const jwtToken = _.get(authUser, "jwtToken", "");

      if (jwtToken) {
        _.set(request, "headers.jwt_token", jwtToken);
      } else if (!request.url!.includes("/auth/tokens/verification")) {
        await Log({
          errorMessage: `Front-end JWT Token is missing, url: ${request.url}`,
        });
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const status = _.get(error, "response.status");
      const errorMessage = _.get(error, "response.data.error.message", "");
  
      if (status === 401 && !originalRequest._retry) {
        const isTokenError =
          errorMessage.includes("invalid signature") ||
          errorMessage.includes("jwt expired");
  
        if (isTokenError) {
          originalRequest._retry = true;
  
          try {
            const user = await validateOktaToken();
  
            localStorage.setItem("authUser", JSON.stringify(user));
            dispatch(logUserIn(true));
            dispatch(isPrivilegedUser(_.get(user, "isPrivilegedUser", false)));
  
            originalRequest.headers["Authorization"] = `Bearer ${user.jwtToken}`;
  
            return axios(originalRequest);
          } catch (tokenRefreshError) {
            console.log("Token refresh failed:", tokenRefreshError);
            await Log({
              errorMessage: `Front-end Token refresh failed: ${JSON.stringify(
                tokenRefreshError
              )}`,
            });
            dispatch(logUserIn(false));
            navigate(`/401`);
            return Promise.reject(tokenRefreshError);
          }
        } else {
          dispatch(logUserIn(true));
          navigate(`/401`);
        }
      }
  
      return Promise.reject(error);
    }
  );

  const resetProperties = () => {
    dispatch(resetState("SOFT_RESET"));
  };

  useEffect(() => {
    resetProperties();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <Header />
      <AppRoutes></AppRoutes>
      <Footer></Footer>
      <Notification></Notification>
    </div>
  );
};
export default RootApp;
