import { useDispatch } from "react-redux";
import "./unauthorized.css"
import { FooterTabPosition } from "../../AppState/footerTabPosition";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import WebAssetOffIcon from '@mui/icons-material/WebAssetOff';
import { useEffect } from "react";

function Unauthorized() {
  const dispatch = useDispatch();
  const { oktaAuth } = useOktaAuth();

  const logout = () => {
    oktaAuth.signOut().then(() => {
      localStorage.clear();
    }).catch(err => {
      console.error('Sign out failed:', err);
    });
  }

  useEffect(() => {
    dispatch(FooterTabPosition(10));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]); 
  
  return (
    <div className="bg-layout access">

      <div className="container">
        <WebAssetOffIcon />
        <h1> 401 - Unauthorized </h1>
        <p>You are not authorized to access this application.</p>

        <br /> 
        <Button
          variant="contained"
          onClick={() => logout()}
          className="assign"
        >Logout from Okta</Button>
      </div>


    </div>
  );
}

export default Unauthorized;
