import React, { useEffect, useMemo, useState } from "react";
import "./Overages.css";
import { overageList } from "../../API/overageList";
import DynamicGrid from "../../components/dynamicGrid/DynamicGrid";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../AppState";
import { FooterTabPosition } from "../../AppState/footerTabPosition";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { DateHelper } from "../../helpers/date.helper";
import { FilterCriteriaValue, Username } from "../../helpers/utils.helper";
import { useLocation } from "react-router-dom";
import { TTerminal } from "../../AppState/overagesFilterFormActions";
import { 
  AppURLs,
  ETab,
  TGridSortModel,
  TPageObject,
} from "../../constants/app";
  import { IRequestParametersOsd } from "../../models/osd";
import { IOverage, IOverageList } from "../../models/overage";
import { pageSelector, sortSelector } from "../../AppState/selectors/dataTableSelectors";

function Overages() {
  const dispatch = useDispatch();
  const location = useLocation();

  const tab: ETab = location.pathname.includes(AppURLs.myAssignments) ? ETab.myAssignments : ETab.overages;
  const currentTerminalValue: TTerminal = useSelector(
    (state: AppState) => state.activeTerminal
  );

  const terminals = useSelector(
    (state: AppState) => state.terminalMaster
  );

  const currentTabPosition: number = useSelector(
    (state: AppState) => state.tabPosition
  );
  const pageObject: TPageObject = useSelector(pageSelector);
  const sortModel: TGridSortModel = useSelector(sortSelector);

  const filterParams = useSelector((state: AppState) => {
    // tabPosition 1 is for Overages otherwise for My Assignment
    if (location.pathname.includes(AppURLs.myAssignments)) {
      return state.myAssignmentsFilterForm;
    } else {
      return state.overagesFilterForm;
    }
  });
  const [rowCount, setRowCount] = useState<number>(0);
  const [overages, setOverages] = useState<IOverage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterCriteria, setFilterCriteria] = React.useState<string>("");

  // Fetch overage data on page load, sorting, pagination,  and filter actions
  // inside getOverageList call the API for overages list
  const getOverageList = () => {
    if (currentTerminalValue.value === "") {
      return;
    }
    const terminalList: string[] = currentTerminalValue.region === null ? terminals
    .filter((terminal) => terminal.region === currentTerminalValue.value)
    .map((t) => t.value)  : [currentTerminalValue.value];
    const isMyAssignmentScreen = location.pathname.includes(AppURLs.myAssignments);
    const params: IRequestParametersOsd = {
      assignedBy: isMyAssignmentScreen ? _.get(filterParams, "assignedBy.as400Id", "").trim(): undefined,
      page: _.get(pageObject, "page", 0) + 1,
      limit: _.get(pageObject, "pageSize", 0),
      sortField: _.get(sortModel, "field", undefined),
      sortOrder: _.get(sortModel, "sort", undefined),
      search: _.get(filterParams, "search", undefined),
      origin: filterParams.origin!,
      destination: filterParams.destination!,
      osdType: filterParams.osdType!,
      entryDateFrom: DateHelper.startDateSelector(Number(filterParams.date)),
      entryDateTo: DateHelper.endDateSelector(Number(filterParams.date)),
      commodityType: filterParams.commodityType!,
      description: _.get(filterParams, "description", undefined),
      proNumber: filterParams?.proNumber ? +filterParams?.proNumber : null,
      od400ProNumber: filterParams?.od400ProNumber
        ? +filterParams?.od400ProNumber
        : null,
      osdNumber: filterParams?.osdNumber ? +filterParams?.osdNumber : null,
      values: filterParams.values!,
      status: filterParams.status!,
      entryBy: _.get(filterParams, "entryUser.as400Id", "").trim(),
      assignedTo: isMyAssignmentScreen ? Username(): _.get(filterParams, "assignedTo.as400Id", "").trim(),
      reporting: isMyAssignmentScreen || currentTerminalValue.value === 'All' ?  undefined : terminalList,
    };
    setFilterCriteria(FilterCriteriaValue(params, tab));
    setOverages([]);
    setLoading(true);
    overageList(params)
      .then((responseData: IOverageList) => {
        setOverages(_.get(responseData, "data", []));
        setRowCount(_.get(responseData, "total", 0));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            err,
            Date.now().toString()
          )
        );
      });
  };

  const columnVisibilityModel = useMemo(() => {
    // tabPosition 1 is for Overages and 2 is for My Assignment
    if (currentTabPosition === ETab.overages) {
      return {
        assignedToName: true,
        assignedByName: false,
      };
    }
    return {
      assignedToName: false,
      assignedByName: true,
    };
  }, [currentTabPosition]);

  //Fetch fresh data and stop loading
  const onSuccessFullyAssigned = () => {
    getOverageList();
  };
  
  useEffect(() => {
    getOverageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerminalValue, pageObject, sortModel, filterParams]);

  useEffect(() => {
    const tab: ETab = location.pathname.includes(AppURLs.myAssignments) ? ETab.myAssignments : ETab.overages;
    dispatch(FooterTabPosition(tab));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dispatch]); 

  return (
    <div>
      <div className="layout overage-layout">
        <div className="filter-criteria">
          Filter Applied :&nbsp;
          {filterCriteria ? <span>{filterCriteria}</span> : "None"}
        </div>

        <DynamicGrid
          id={"osdNumber"}
          checkboxSelection={true}
          key={`${currentTerminalValue}-${tab}`}
          rows={overages}
          tab={tab}
          loading={loading}
          rowCount={rowCount}
          onSuccessFullyAssigned={onSuccessFullyAssigned}
          columnVisibilityModel={columnVisibilityModel}
        ></DynamicGrid>
        <div></div>
      </div>
    </div>
  );
}

export default Overages;
