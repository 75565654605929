import { createSelector } from "reselect";
import { AppState } from "../appState";
import { IFilterDropdown } from "../overagesFilterFormActions";

// Base selector to access commodity state
const commodityState = (state: AppState) => state.filterCommodityTypeData;

// Memoized selector to get commodity details
export const commoditySelector = createSelector(
  [commodityState],
  (commodity) => commodity
);

// Memoized selector to transform the data
export const commodityDisplayValueSelector = createSelector(
  [commodityState],
  (filterCommodityTypeData): string[] =>
    filterCommodityTypeData.map((item: IFilterDropdown) => item.displayValue)
);
