import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { toRelativeUrl } from "@okta/okta-auth-js";
import { Outlet } from "react-router-dom";
import Loading from "./Loading";
import { useSelector } from "react-redux";
import { AppState } from "../../AppState/appState";
import _ from "lodash";

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const isLoggedIn: boolean = useSelector(
    (state: AppState) => state.isLoggedIn
  );

  useEffect(() => {
    console.log("requiredAuth useEffect 1, authState:", authState);
    if (!authState) {
      return;
    }
    console.log("requiredAuth useEffect 2, authState?.isAuthenticated: ", authState?.isAuthenticated);

    if (!authState?.isAuthenticated) {
      try {
        localStorage.clear();
        const originalUri = toRelativeUrl(
          window.location.href,
          window.location.origin
        );
        oktaAuth.setOriginalUri(originalUri);
        oktaAuth.signInWithRedirect();
      } catch (error) {
        console.error("Error during authentication flow:", error);
      }
    }
  }, [oktaAuth, authState, isLoggedIn]);

  console.log(
    "before condition !authState?.isAuthenticated || !isLoggedIn 3, authState?.isAuthenticated : ",
    authState?.isAuthenticated
  );
  console.log(
    "before condition !authState?.isAuthenticated || !isLoggedIn 4, isLoggedIn :  ",
    isLoggedIn
  );
  if (!authState?.isAuthenticated || !isLoggedIn) {
    console.log("Inside scope of condition !authState?.isAuthenticated || !isLoggedIn 5, !authState?.isAuthenticated:", !authState?.isAuthenticated);
    console.log("Inside scope of condition !authState?.isAuthenticated || !isLoggedIn 6, !isLoggedIn", !isLoggedIn);
    return <Loading />;
  }


  console.log("Application Loaded");

  return <Outlet />;
};
