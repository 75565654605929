import { createSelector } from "reselect";
import { AppState } from "../appState";
import { ETab } from "../../constants/app";
import { tabPositionSelector } from "./tabSelectors";

// Base selectors

const overagesFilterFormState = (state: AppState) =>
  state.overagesFilterForm;
const myAssignmentsFilterFormState = (state: AppState) =>
  state.myAssignmentsFilterForm;
const shortagesFilterFormState = (state: AppState) =>
  state.shortagesFilterForm;


// Memoized selector to get overages Filter Form
export const overagesFilterFormSelector = createSelector(
  [overagesFilterFormState],
  (overagesFilterForm) => overagesFilterForm
);

// Memoized selector to get  Filter Form based on tab position
export const filterFromSelector = createSelector(
  [
    tabPositionSelector,
    overagesFilterFormState,
    myAssignmentsFilterFormState,
    shortagesFilterFormState,
  ],
  (
    tabPosition,
    overagesFilterForm,
    myAssignmentsFilterForm,
    shortagesFilterForm
  ) => {
    if (tabPosition === ETab.overages) {
      return overagesFilterForm;
    } else if (tabPosition === ETab.myAssignments) {
      return myAssignmentsFilterForm;
    } else {
      return shortagesFilterForm;
    }
  }
);
