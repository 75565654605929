import React, { useEffect, useState } from "react";
import "./shortage.css";
import DynamicGrid from "../../components/dynamicGrid/DynamicGrid";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import AppState from "../../AppState";
import { FooterTabPosition } from "../../AppState/footerTabPosition";
import { PopoutNotification } from "../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import { DateHelper } from "../../helpers/date.helper";
import { FilterCriteriaValue } from "../../helpers/utils.helper";
import { TTerminal } from "../../AppState/overagesFilterFormActions";
import { IShortage, IShortagesList } from "../../models/shortage";
import { GetShortagesAPI } from "../../API/getShortages";
import { ETab,  TGridSortModel, TPageObject, } from "../../constants/app";
import { IRequestParametersOsd } from "../../models/osd";

function Shortage() {
  const dispatch = useDispatch();
  const currentTerminalValue: TTerminal = useSelector((state: AppState) => state.activeTerminal);
  const terminals = useSelector((state: AppState) => state.terminalMaster);
  const pageObject: TPageObject = useSelector((state: AppState) =>  state.shortagesPageObject);
  const sortModel: TGridSortModel = useSelector((state: AppState) => state.shortagesSortObject);
  const filterParams = useSelector((state: AppState) => state.shortagesFilterForm);
  const [rowCount, setRowCount] = useState<number>(0);
  const [shortages, setShortages] = useState<IShortage[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filterCriteria, setFilterCriteria] = React.useState<string>("");

  // Fetch overage data on page load, sorting, pagination,  and filter actions
  // inside fetchShortages call the API for shortages list
  const fetchShortages = () => {
    if (currentTerminalValue.value === "") {
      return;
    }
    const terminalList: string[] = currentTerminalValue.region === null ? terminals
    .filter((terminal) => terminal.region === currentTerminalValue.value)
    .map((t) => t.value)  : [currentTerminalValue.value];
    const params: IRequestParametersOsd = {
      page: _.get(pageObject, "page", 0) + 1,
      limit: _.get(pageObject, "pageSize", 0),
      sortField: _.get(sortModel, "field", undefined),
      sortOrder: _.get(sortModel, "sort", undefined),
      search: _.get(filterParams, "search", undefined),
      origin: filterParams.origin!,
      destination: currentTerminalValue.value === 'All' ?  undefined : terminalList,
      osdType: filterParams.osdType!,
      entryDateFrom: DateHelper.startDateSelector(Number(filterParams.date)),
      entryDateTo: DateHelper.endDateSelector(Number(filterParams.date)),
      commodityType: filterParams.commodityType!,
      description: _.get(filterParams, "description", undefined),
      proNumber: filterParams?.proNumber ? +filterParams?.proNumber : null,
      osdNumber: filterParams?.osdNumber ? +filterParams?.osdNumber : null,
      values: filterParams.values!,
      status: filterParams.status!,
      entryBy: _.get(filterParams, "entryUser.as400Id", "").trim(),
      assignedTo: _.get(filterParams, "assignedTo.as400Id", "").trim(),
      reporting: filterParams.reporting!
    };
    setFilterCriteria(FilterCriteriaValue(params, ETab.shortages));
    setShortages([]);
    setLoading(true);
    GetShortagesAPI(params)
      .then((responseData: IShortagesList) => {
        setShortages(_.get(responseData, "data", []));
        setRowCount(_.get(responseData, "total", 0));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            err,
            Date.now().toString()
          )
        );
      });
  };

  //Fetch fresh data and stop loading
  const onSuccessFullyAssigned = () => {
    fetchShortages();
  };

  useEffect(() => {
    if(!loading)
      fetchShortages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTerminalValue, pageObject, sortModel, filterParams]);

  useEffect(() => {
    dispatch(FooterTabPosition(ETab.shortages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]); 

  return (
    <div>
      <div className="layout overage-layout">
        <div className="filter-criteria">
          Filter Applied :&nbsp;
          {filterCriteria ? <span>{filterCriteria}</span> : "None"}
        </div>

        <DynamicGrid
          id={"osdNumber"}
          checkboxSelection={true}
          key={`${currentTerminalValue}`}
          rows={shortages}
          loading={loading}
          rowCount={rowCount}
          onSuccessFullyAssigned={onSuccessFullyAssigned}
          tab={ETab.shortages}
        ></DynamicGrid>
        <div></div>
      </div>
    </div>
  );
}

export default Shortage;
