import { createSelector } from "reselect";
import { AppState } from "../appState";
import { ETab } from "../../constants/app";
import { tabPositionSelector } from "./tabSelectors";
// Base selectors
const shortageStatusMasterState = (state: AppState) =>
  state.shortageStatusMasterData;
const filterStatusMasterState = (state: AppState) => state.filterStatusData;

// Memoized selector to get investigation status based on tab position
export const investigationStatusSelector = createSelector(
  [tabPositionSelector, shortageStatusMasterState, filterStatusMasterState],
  (tabPosition, shortageStatusMaster, filterStatusMaster) => {
    if (tabPosition === ETab.shortages) {
      return shortageStatusMaster;
    } else {
      return filterStatusMaster;
    }
  }
);
