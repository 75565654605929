import { ApiType } from "./Types";
import { env } from "../constants/env";

/**
 * A function to generate the correct URL for API calls
 * @param apiType - The type of API URL needed
 * @returns - A URL to make an API call, factoring in the current ENV
 */
export const getUrl = (apiType: ApiType): string => {
  const baseUrl = `${env.wnrApi.url}${env.wnrApi.path}`;
  const endpointMap: { [key in ApiType]: string } = {
    [ApiType.TERMINAL]: "/master/terminals",
    [ApiType.VALIDATE_OKTA_TOKEN]: "/auth/tokens/verification",
    [ApiType.OVERAGE_LIST]: "/overages/list",
    [ApiType.OVERAGE_CREATE]: "/overages",
    [ApiType.MASTER_RECORDS]: "/master/records",
    [ApiType.OVERAGE_FILTER_MASTER_USER]: "/master/users",
    [ApiType.EXCEPTION_ASSIGN]: "/exception/assignments",
    [ApiType.DASHBOARD_KRA]: "/dashboard/summaries",
    [ApiType.EXCEPTION_DETAILS]: "/exception",
    [ApiType.OVERAGE_ENTRY_DETAILS]: "/overages/edit",
    [ApiType.PRO_IMAGES]: "/files/get",
    [ApiType.MANIFEST_DETAILS]: "/manifests",
    [ApiType.MANIFEST_PRO_DETAILS]: "/manifests-pro",
    [ApiType.SEND_APB_MAIL]: "/exception/:osdId/apb/send",
    [ApiType.MASTER_PRO_NUMBER]: "/master/pro-numbers",
    [ApiType.MASTER_OSD_NUMBER]: "/master/osd-numbers",
    [ApiType.COMMODITY_TYPE_MASTER]: "/master/commodity-types",
    [ApiType.EXCEPTION_CODE_MASTER]: "/master/exception-codes",
    [ApiType.CHECK_PRO]: "/pro",
    [ApiType.OVERAGE_UPDATE]: "/overages",
    [ApiType.SAVE_OR_SUBMIT_INVESTIGATION]: "/overages",
    [ApiType.LOCATION_MASTER]: "/master/locations",
    [ApiType.UPLOAD_FILE]: "/files/upload",
    [ApiType.DOC_TYPE]: "/files/docTypes",
    [ApiType.GET_SHORTAGES]: "/shortages/list",
    [ApiType.PRINT]: "/overages/:osdNumber/print",
    [ApiType.SHORTAGE_ENTRY_CREATE]: "/all-short",
    [ApiType.AS_ENTRY_DETAILS]: "/all-short/edit",
    [ApiType.DS_ENTRY_DETAILS]: "/delivered-short/edit",
    [ApiType.SHORTAGE_INVESTIGATION]: "/all-short/:osdId/investigation",
    [ApiType.AS_ENTRY_UPDATE]: "/all-short",
    [ApiType.DS_ENTRY_UPDATE]: "/delivered-short",
    [ApiType.LOGGLY_LOG]: "/system/log",
  };

  return endpointMap[apiType] ? `${baseUrl}${endpointMap[apiType]}` : "http://localhost:1337";
};
