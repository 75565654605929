import { IException } from "./osd";

export interface IShortage extends IException {
    entryBy: string;
    lastManifest?: number;
}

export interface IShortagesList {
    data: IShortage[];
    total:  number;
    page:  number;
    limit:  number;
    totalPages:  number;
}

export interface IDay5Investigation {
  terminal?: string;
  manifest?: number | null | undefined;
  discrepancies?: string 
}
export interface IAllShortageInvestigation {
    showAtDestTerm?: string;
    dateToDestTerm?: string;
    notInDestTerm?: string;
    notInDestTermSteps?: string;
    noMovement?: string;
    dateLastMovement?: string;
    noMovementSteps?: string;
    suffixBill?: string;
    suffixBillSteps?: string;
    bolCheck?: string;
    bolCheckSteps?: string;
    drCheck?: string;
    drCheckSteps?: string;
    pumCheck?: string;
    pickupManifest?: number | null;
    pumSteps?: string;
    shipperLoad?: string;
    discrepancies?: string;
    checkLinehaulManifests?: string;
    linehaulManifests?: string;
    interviewWorkers?: string;
    workerSteps?: string;
    consigneePhone?: string;
    consigneeName?: string;
    consigneeShort?: string;
    itemNumbers?: string;
    orderReceived?: string;
    freightLabeled?: string;
    shipperPhone?: string;
    shipperName?: string;
    shippedDetails?: string;
    shortShipped?: string;
    productValue?: string;
    multShipments?: string;
    check7818?: string;
    details7818?: string;
    manifested?: string;
    cameraFindings?: string;
    callSameDays?: string;
    sameDayPros?: string;
    contactTerminals?: string;
    investigationDetails?: IDay5Investigation[];
    followUp?: string;
    followUpDetails?: string;
    check7818_2?: string;
    details7818_2?: string;
    highValue?: string;
    finalReCheck?: string;
    freightLocated?: string;
    lastStatusNumber?:number;


    confirmStatus?: string;
    confirmStatusSteps?: string;
    confirmDelivery?: string;
    confirmDeliverySteps?: string;
    driverSpot?: string;
    driverSpotSteps?: string;
    bolConfirm?: string;
    bolDuplicate?: string;
    isDr?: string;    
    confirmCompletion?: string;
    confirmCompletionSteps?: string;
    shipperLoadCount?: string;   
    anyDiscrepancies?: string;  
    didYouTalk?: string;
    freightPulled?: string;
    shipmentLoadOut?: string;
    deliveredADE?: string;
    freightNose?: string;
    ordinaryDelivery?: string;
    cameraDT?: string;
    callSameDay?: string;
    proDelivery?: string;
}

export interface IDeliveredShort extends IASEntry {
    shrinkWrappedPalletCount?: string;
    wasOriginalShrinkwrapIntact?: string;
    description: string;
  }
  

export interface IASEntry {
    proNumber?: number | string | null;
    osdNumber?: number | string;
    reporting?: string;
    username?: string;
    origin?: string;
    destination?: string;
    shipperAccount?: string;
    consigneeAccount?: string;
    type?: string;
    shipper?: string;
    shipperAddress?: string;
    shipperCity?: string;
    shipperState?: string;
    shipperZip: string;
    consigneeCity?: string;
    consigneeState?: string;
    consigneeZip: string;
    consignee?: string;
    consigneeAddress?: string;
    itemCartonModelSerialNumber?: string;
    weight?: string;
    manufacturerBrand?: string;
    lastManifest?: number;
    manifest?: number;
    trailer?: string;
    bay?: string;
    commodity?: string;
    package?: string;
    pieces?: number;
    valueRange?: string;
    dimension?: string;
    packagingDescription?: string;
    additionalDescription?: string;
    productDescription?: string;
    agentInterline?: string;
    trailerOrBay?: string;
    value1k?: number | null;
    investigationStatus?: string;
    investigationStatusNumber?: number;
    investigationFreightLocated?: string;
    investigationLastStatusNumber: number
    lastUpdatedDate?: number;
    lastUpdatedTime?: number;
    createdDate?: number;
    createdTime?: number;
  }
  
  
export interface IASEntryCreateParams {
    proNumber?: number | string | null;
    osdNumber?: number | string;
    reporting?: string;
    type?: string;
    itemCartonModelSerialNumber?: string;
    weight?: number;
    lastManifest?: number;
    manifest?: number;
    trailer?: string;
    bay?: string;
    commodity?: string;
    package?: string;
    pieces?: number;
    valueRange?: string;
    dimension?: string;
    packagingDescription?: string;
    additionalDescription?: string;
    productDescription?: string;
    agentInterline?: string;
    trailerOrBay?: string;
    value1k?: number | null;
    origin?: string;
    destination?: string;
    shipper?: string;
    shipperAccount?: string;
    shipperAddress?: string;
    shipperCity?: string;
    shipperState?: string;
    shipperZip?: string;
    consignee?: string;
    consigneeAccount?: string;
    consigneeAddress?: string;
    consigneeCity?: string;
    consigneeState?: string;
    consigneeZip?: string;
}

export interface IShortageEntryDetails extends IAllShortageInvestigation, IASEntry {}


export enum EASInvestigationFields {
    ShowAtDestTerm = 'showAtDestTerm',
    DateToDestTerm = 'dateToDestTerm',
    NotInDestTerm = 'notInDestTerm',
    NotInDestTermSteps = 'notInDestTermSteps',
    NoMovement = 'noMovement',
    DateLastMovement = 'dateLastMovement',
    NoMovementSteps = 'noMovementSteps',
    SuffixBill = 'suffixBill',
    SuffixBillSteps = 'suffixBillSteps',
    BolCheck = 'bolCheck',
    BolCheckSteps = 'bolCheckSteps',
    DrCheck = 'drCheck',
    DrCheckSteps = 'drCheckSteps',
    PumCheck = 'pumCheck',
    PickupManifest = 'pickupManifest',
    PumSteps = 'pumSteps',
    ShipperLoad = 'shipperLoad',
    Discrepancies = 'discrepancies',
    CheckLinehaulManifests = 'checkLinehaulManifests',
    LinehaulManifests = 'linehaulManifests',
    InterviewWorkers = 'interviewWorkers',
    WorkerSteps = 'workerSteps',
    ConsigneePhone = 'consigneePhone',
    ConsigneeName = 'consigneeName',
    ConsigneeShort = 'consigneeShort',
    ItemNumbers = 'itemNumbers',
    OrderReceived = 'orderReceived',
    FreightLabeled = 'freightLabeled',
    ShipperPhone = 'shipperPhone',
    ShipperName = 'shipperName',
    ShippedDetails = 'shippedDetails',
    ShortShipped = 'shortShipped',
    ProductValue = 'productValue',
    MultShipments = 'multShipments',
    Check7818 = 'check7818',
    Details7818 = 'details7818',
    Manifested = 'manifested',
    CameraFindings = 'cameraFindings',
    CallSameDays = 'callSameDays',
    SameDayPros = 'sameDayPros',
    ContactTerminals = 'contactTerminals',
    InvestigationDetails = 'investigationDetails',
    Terminal = 'terminal',
    Manifest = 'manifest',
    FollowUp = 'followUp',
    FollowUpDetails = 'followUpDetails',
    Check7818_2 = 'check7818_2',
    Details7818_2 = 'details7818_2',
    HighValue = 'highValue',
    FinalReCheck = 'finalReCheck',


    ConfirmStatus = 'confirmStatus',
    ConfirmStatusSteps = 'confirmStatusSteps',
    ConfirmDelivery = 'confirmDelivery',
    ConfirmDeliverySteps = 'confirmDeliverySteps',

    DriverSpotSteps = 'driverSpotSteps',
    DriverSpotStepsSteps = 'driverSpotStepsSteps',
    BolDuplicate= 'bolDuplicate',
    ConfirmCompletionSteps = 'confirmCompletionSteps',
    AnyDiscrepancies = 'anyDiscrepancies',
    DidYouTalk = 'didYouTalk',
    FreightPulled = 'freightPulled',
    ShipmentLoadOut = 'shipmentLoadOut',
    DeliveredADE = 'deliveredADE',
    FreightNose = 'freightNose',
    OrdinaryDelivery = 'ordinaryDelivery',
    CameraDT = 'cameraDT',
    CallSameDay = 'callSameDay',
    ProDelivery= 'proDelivery',
}
  
export interface IDSEntry {
  itemCartonModelSerialNumber? : string;
  weight? : number;
  commodity? : string;
  package? : string;
  pieces? : number;
  description? : string;
  osdNumber?: number
}