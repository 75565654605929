import Button from "@mui/material/Button";
import "./OverageEntry.css";
import AccordionDetails from "@mui/material/AccordionDetails";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import InfoIcon from "@mui/icons-material/Info";
import { useFormik } from "formik";
import { overageInvestigationNBSchema } from "../../schema";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { APP, EFormAction, EUploadKey } from "../../constants/app";
import Loader from "../../components/loader/loader";
import { PopoutNotification } from "../../AppState/popoutNotification";
import { SaveOrSubmitInvestigationAPI } from "../../API/saveOrSubmitInvestigation";
import NOTIFICATION_TYPE from "../../AppState/NotificationType";
import _ from "lodash";
import { FileUploadAPI } from "../../API/fileUpload";
import { IUploadFile } from "../../models/master";
import { HtmlToImage } from "../../helpers/utils.helper";
import { isFormValueChanged } from "../../AppState/isFormValueChanged";
import Snackbar from "@mui/material/Snackbar";
import { State } from "../../components/shared/Notification";
import { IOvInvestigation } from "../../models/overage";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";

interface TooltipState {
  [key: string]: boolean;
}

type Props = {
  exceptionType?: string;
  osdNumber?: number;
  proNumber?: number | string | null;
  isEditMode?: boolean;
  investigationDetails?: IOvInvestigation;
  setInvestigationStatus: (status: string) => void;
  expanded?: string | boolean;
};
const InvestigationForm: React.FC<Props> = (props: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;
  
  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const [initFormValue, setInitFormValue]= React.useState<IOvInvestigation>(props?.investigationDetails || {
    osdType: props?.exceptionType?.trim(),
    od400Check: "",
    od400CheckResponse: "",
    markingsCheck: "",
    markingsCheckResponse: "",
    thCheck: "",
    thCheckResponse: "",
    mfCheck: "",
    mfCheckResponse: "",
    osAndDCheck: "",
    osAndDCheckResponse: "",
    googleCheck: "",
    googleCheckResponse: "",
    unmanifestedCheck: "",
    unmanifestedCheckResponse: "",
    undeliveredCheck: "",
    undeliveredCheckResponse: "",
    customerDeliveryCheck: "",
    customerDeliveryCheckResponse: "",
    undelInvCheck: "",
    undelInvCheckResponse: "",
    apbCheck: "",
    apbCheckResponse: "",
    emailCheck: "",
    emailCheckResponse: "",
    billOfLadingCheckResponse: "",
    billOfLadingCheck: "",
    drCheck: "",
    drCheckResponse: "",
    psCheck: "",
    psCheckResponse: "",
    consigneeCheck: "",
    consigneeCheckResponse: "",
    shipmentCheck: "",
    shipmentCheckResponse: "",
  });
  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    props?.isEditMode || false
  );
  const [isEditFormValuePatched, setIsEditFormValuePatched] = React.useState<boolean>(false);
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<string>("");
  const [investigation, setInvestigation] = React.useState<IOvInvestigation>({});
  const [isEditMode, setIsEditMode] = React.useState<boolean>(false);
  const enableEditing = (e: any) => {
    e.preventDefault();
    setFormFieldsDisabled(false);
  };

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
    validateForm,
    resetForm
  } = useFormik({
    validationSchema: overageInvestigationNBSchema,
    initialValues: initFormValue,
    onSubmit: (values) => {
      saveOrSubmit("submit");
    },
  });

  const reset = (action: EFormAction) => {
    if (action === EFormAction.create) {
      resetForm();
      setFieldValue("osdType", props?.exceptionType?.trim());
      validateForm(values)
    } else if (action === EFormAction.edit) {
      patchFormValue();
    }
  }

  const saveOrSubmit = (action: string) => {
    setPageLoading(true);
    const reqParams = JSON.parse(JSON.stringify(values));
    if (reqParams.osdType === "NB") {
      APP.OVInvestigationFields.forEach((f: string) => {
        delete reqParams[f];
      });
    } else {
      APP.NBInvestigationFields.forEach((f: string) => {
        delete reqParams[f];
      });
    }
    reqParams["od400Check"] = values["od400Check"];
    reqParams["od400CheckResponse"] = values["od400CheckResponse"];
    SaveOrSubmitInvestigationAPI(reqParams, props.osdNumber!, action)
      .then((res) => {
        setPageLoading(false);
        setInvestigation(res);
        if(action === "save") {
          dispatch(
            PopoutNotification(
              NOTIFICATION_TYPE.SUCCESS,
              `OS&D Investigation notes have been saved successfully`,
              Date.now().toString()
            )
          );
        } else {
          investigationImageUpload();
        }
        dispatch(isFormValueChanged(false));
        setIsEditFormValuePatched(false);
        setStatus(res.status);
        props?.setInvestigationStatus(res.status);
        setIsEditMode(true);
        setFormFieldsDisabled(true);
        validateForm(values);
      })
      .catch((err) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof err === 'string' ? err : `OS&D Investigation notes ${action} failed`,
            Date.now().toString()
          )
        );
      });
  };

  // user can capture image of investigation questions and answers form after successfully submitted

  const investigationImageUpload = async () => {
    setPageLoading(true);
     
    const images = await HtmlToImage("req", "1.jpeg", 0.2);
    const reqInput: IUploadFile = {
      osdNumber: props.osdNumber,
      docType: "FX",
      uploadKey: EUploadKey.od400Pro,
    };
    FileUploadAPI([images], reqInput)
      .then((res) => {
        setPageLoading(false);
        const errors = _.get(res, "errors", []);
        const notificationType = errors.length > 0 ? NOTIFICATION_TYPE.ERROR : NOTIFICATION_TYPE.SUCCESS;
        const message = errors.length > 0 
          ? errors.join(", ") 
          : res.result || 'OS&D Investigation Image uploaded successfully';

        dispatch(PopoutNotification(notificationType, message, Date.now().toString()));
      })
      .catch((error) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            typeof error === "string"
              ? error
              : `OS&D Investigation Image uploading failed`,
            Date.now().toString()
          )
        );
      });
  };

  const patchFormValue = () => {
    resetForm();
    if ((props?.isEditMode || isEditMode) && ( props?.investigationDetails || !_.isEmpty(investigation))) {
      const source :IOvInvestigation = (_.isEmpty(investigation) ? props?.investigationDetails : investigation)!;
      const fields:  Array<keyof IOvInvestigation> = [
        ...APP.OVInvestigationFields,
        ...APP.NBInvestigationFields,
      ];
      fields.forEach((f: keyof IOvInvestigation) => {
        const fieldValue = source[f]
        setFieldValue(f, typeof fieldValue === 'string' ? fieldValue.trim() : "");
      });
      setFieldValue("osdType", props?.exceptionType?.trim());
      setIsEditFormValuePatched(true);
    }
  }

    // Declare a new state variable for tooltips with a default empty object
    const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

    // Function to handle tooltip close
    const handleTooltipClose = (id: string) => {
      setOpenTooltips((prev) => ({ ...prev, [id]: false }));
    };
  
    // Function to handle tooltip open
    const handleTooltipOpen = (id: string) => {
      setOpenTooltips((prev) => ({ ...prev, [id]: true }));
    };

  useEffect(() => {
    setFieldValue("osdType", props?.exceptionType?.trim());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.exceptionType]);

  useEffect(() => {
    if (isEditFormValuePatched) {
      setInitFormValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditFormValuePatched]);

  useEffect(() => {
    patchFormValue();
    setStatus(props?.investigationDetails?.investigationStatus || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.investigationDetails]);

  useEffect(() => {
    if (props?.isEditMode && props?.investigationDetails) {
      validateForm(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (props?.expanded !== "panel2") {
      reset(props?.isEditMode || isEditMode ? EFormAction.edit : EFormAction.create);
      setFormFieldsDisabled(props?.isEditMode || isEditMode)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.expanded]);


  useEffect(() => {
    if (isEditFormValuePatched || !props?.isEditMode) {
      dispatch(isFormValueChanged(!_.isEqual(initFormValue, values)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditFormValuePatched, values, initFormValue]);

  return (
      <div>
        <form action="" onSubmit={handleSubmit}>
          <AccordionDetails>
            <div >

              <div className="print-doc">
                <br />
                <span className="print-title">Overage Investigation - Exception </span>
                <span className="print-title">#{props?.osdNumber}</span>
                {props?.exceptionType === 'OV' &&
                  <>,
                    <span className="print-title">&nbsp; Pro #</span>
                    <span className="print-title">{props?.proNumber}</span>
                  </>
                }
                <br />
              </div>

              {props?.exceptionType === "NB" && (
                <div>
                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel id="od400Check">
                          Was OD400 created by the dock?{" "}
                          {/* <InfoIcon
                          className="info-icon"
                          titleAccess=" Use option 5 FB Master Look Up. Search: PO#, BOL#, SN #, MFG#, Shipper/Cons name etc."
                        />{" "} */}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="od400Check"
                              className="textarea-radio-group"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="od400Check"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.od400Check === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="od400Check"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.od400Check === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.od400CheckResponse}
                                id="comments"
                                name="od400CheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.od400CheckResponse &&
                                touched.od400CheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.od400CheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.od400Check && touched.od400Check ? (
                          <span className="form-error hide-error">{errors.od400Check}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you utilize the markings off of the freight to find
                          the PRO# that may apply?{" "}
                          {['1'].map((id) => (
                            <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                              <Tooltip
                                PopperProps={{
                                disablePortal: true,
                                }}
                                onClose={() => handleTooltipClose(id)}
                                open={openTooltips[id] || false}
                                disableFocusListener 
                                disableTouchListener
                                title="Use option 5 FB Master Look Up. Search: PO#, BOL#, SN #, MFG#, Shipper/Cons name etc."
                              > 
                                <InfoIcon
                                  className="info-icon"
                                  onMouseEnter={() => handleTooltipOpen(id)}
                                  onMouseLeave={() => handleTooltipClose(id)}
                                />
                              </Tooltip>
                            </ClickAwayListener>
                          ))}
                          
                          
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="markingsCheck"
                              className="textarea-radio-group"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="markingsCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.markingsCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="markingsCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.markingsCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.markingsCheckResponse}
                                id="comments"
                                name="markingsCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.markingsCheckResponse &&
                                touched.markingsCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.markingsCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.markingsCheck && touched.markingsCheck ? (
                          <span className="form-error hide-error">{errors.markingsCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you view the trailer history?{" "}
                          {['2'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use option 62 to check other moves of this same trailer prior to your receipt. Check those pros and manifests."
                          > 
                          <InfoIcon
                            className="info-icon" 
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="thCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="thCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.thCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="thCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.thCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.thCheckResponse}
                                id="comments"
                                name="thCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.thCheckResponse && touched.thCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.thCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.thCheck && touched.thCheck ? (
                          <span className="form-error hide-error">{errors.thCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          If freight was found on a manifest, did you search that
                          manifest for other shipments marked as short or
                          undelivered?{" "}
                          {['3'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Check OD400 for Manifest #. Use option 16 for manifest history"
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)} 
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="mfCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="mfCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.mfCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="mfCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.mfCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.mfCheckResponse}
                                id="comments"
                                name="mfCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.mfCheckResponse && touched.mfCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.mfCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.mfCheck && touched.mfCheck ? (
                          <span className="form-error hide-error">{errors.mfCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you check the OS&D master reporting for descriptions
                          of open shortages and ADE's where the overage may apply?{" "}
                          {['4'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use menu option 78/18 to search for exceptions, example: AS/DS/NF/BNF/TS/ etc."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="osAndDCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="osAndDCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.osAndDCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="osAndDCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.osAndDCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.osAndDCheckResponse}
                                id="comments"
                                name="osAndDCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.osAndDCheckResponse &&
                                touched.osAndDCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.osAndDCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.osAndDCheck && touched.osAndDCheck ? (
                          <span className="form-error hide-error">{errors.osAndDCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you google search the Manufacturer, distributor and/or
                          product information? If so, did you use that information
                          in freight bill tracing and/or OS&D searches?{" "}
                          {['5'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use options 5 & 78/18 to look for similar shipments with open shortages or ADE's."
                          >
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="googleCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="googleCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.googleCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="googleCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.googleCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.googleCheckResponse}
                                id="comments"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                                name="googleCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                              />
                              {errors.googleCheckResponse &&
                                touched.googleCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.googleCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.googleCheck && touched.googleCheck ? (
                          <span className="form-error hide-error">{errors.googleCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you check the unmanifested outbound and inbound
                          freight reports to see if overage may match a non-movement
                          shipment?{" "}
                          {['6'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use menu option 40 unmanifested outbound freight and option 41 unmanifested inbound freight to see if overage belongs to one of those shipments."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="unmanifestedCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="unmanifestedCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.unmanifestedCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="unmanifestedCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.unmanifestedCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.unmanifestedCheckResponse}
                                id="comments"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                                name="unmanifestedCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                              />
                              {errors.unmanifestedCheckResponse &&
                                touched.unmanifestedCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.unmanifestedCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.unmanifestedCheck && touched.unmanifestedCheck ? (
                          <span className="form-error hide-error">
                            {errors.unmanifestedCheck}
                          </span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you search undelivered outbound and inbound shipments
                          by shipper or account code that have not been delivered?{" "}
                          {['7'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use menu option 78/30."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="undeliveredCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="undeliveredCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.undeliveredCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="undeliveredCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.undeliveredCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.undeliveredCheckResponse}
                                id="comments"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                                name="undeliveredCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                              />
                              {errors.undeliveredCheckResponse &&
                                touched.undeliveredCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.undeliveredCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.undeliveredCheck && touched.undeliveredCheck ? (
                          <span className="form-error hide-error">
                            {errors.undeliveredCheck}
                          </span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you use the customer delivery analysis report to check
                          shipments picked up or going to a specific account code
                          during a specific time frame?{" "}
                          {['8'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use AS400 menu options 85/20."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="customerDeliveryCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="customerDeliveryCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.customerDeliveryCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="customerDeliveryCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.customerDeliveryCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.customerDeliveryCheckResponse}
                                id="comments"
                                name="customerDeliveryCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.customerDeliveryCheckResponse &&
                                touched.customerDeliveryCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.customerDeliveryCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.customerDeliveryCheck &&
                          touched.customerDeliveryCheck ? (
                          <span className="form-error hide-error">
                            {errors.customerDeliveryCheck}
                          </span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you check your undelivered and inventory reports for
                          any shipments not yet delivered?{" "}
                          {['10'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Use AS400 menu option 43 for undelivered and 87 for inventory."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="undelInvCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="undelInvCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.undelInvCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="undelInvCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.undelInvCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.undelInvCheckResponse}
                                id="comments"
                                name="undelInvCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.undelInvCheckResponse &&
                                touched.undelInvCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.undelInvCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.undelInvCheck && touched.undelInvCheck ? (
                          <span className="form-error hide-error">{errors.undelInvCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you send APB to ALLOSD (If the overage value is
                          greater than $1,000)? <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="apbCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="apbCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.apbCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="apbCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.apbCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.apbCheckResponse}
                                id="comments"
                                name="apbCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.apbCheckResponse &&
                                touched.apbCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.apbCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.apbCheck && touched.apbCheck ? (
                          <span className="form-error hide-error">{errors.apbCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Did you monitor all ALL OSD e-mail for possible match?{" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="emailCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="emailCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.emailCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="emailCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.emailCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.emailCheckResponse}
                                name="emailCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                id="comments"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.emailCheckResponse &&
                                touched.emailCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.emailCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                      </FormControl>
                      {errors.emailCheck && touched.emailCheck ? (
                        <span className="form-error hide-error">{errors.emailCheck}</span>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              )}

              {props?.exceptionType === "OV" && (
                <div>
                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel id="od400Check">
                          Was OD400 created by the dock?{" "}
                          {/* <InfoIcon
                          className="info-icon"
                          titleAccess=" Use option 5 FB Master Look Up. Search: PO#, BOL#, SN #, MFG#, Shipper/Cons name etc."
                        />{" "} */}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="od400Check"
                              className="textarea-radio-group"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="od400Check"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.od400Check === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="od400Check"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.od400Check === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.od400CheckResponse}
                                id="comments"
                                name="od400CheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.od400CheckResponse &&
                                touched.od400CheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.od400CheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.od400Check && touched.od400Check ? (
                          <span className="form-error hide-error">{errors.od400Check}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group ">
                        <FormLabel >
                          Do you check the bill of lading for a billing error?{" "}
                          {['11'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Review piece count of what the driver signed for compared to what is shown on BOL."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="billOfLadingCheckResponse"
                              name="billOfLadingCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="billOfLadingCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.billOfLadingCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="billOfLadingCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.billOfLadingCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.billOfLadingCheckResponse}
                                name="billOfLadingCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                id="billOfLadingCheckResponse"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.billOfLadingCheckResponse &&
                                touched.billOfLadingCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.billOfLadingCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.billOfLadingCheck && touched.billOfLadingCheck ? (
                          <span className="form-error hide-error">
                            {errors.billOfLadingCheck}
                          </span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group">
                        <FormLabel >
                          Did you check the DR to confirm what the consignee signed
                          for?{" "}
                          {['12'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Confirm the actual piece count signed for."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="drCheckResponse"
                              name="drCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="drCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.drCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="drCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.drCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.drCheckResponse}
                                name="drCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                id="drCheckResponse"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.drCheckResponse && touched.drCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.drCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.drCheck && touched.drCheck ? (
                          <span className="form-error hide-error">{errors.drCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group">
                        <FormLabel >
                          If a packing slip was found, did you check the packing
                          slip to determine what the shipment consisted of?{" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="psCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="psCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.psCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="psCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.psCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.psCheckResponse}
                                name="psCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                id="psCheckResponse"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.psCheckResponse && touched.psCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.psCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.psCheck && touched.psCheck ? (
                          <span className="form-error hide-error">{errors.psCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>

                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group">
                        <FormLabel >
                          Did you reach out to the consignee to verify what they
                          received on their delivery?{" "}
                          {['13'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Do not advise either customer that we have overage freight."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="consigneeCheck"
                              name="consigneeCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="consigneeCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.consigneeCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="consigneeCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.consigneeCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.consigneeCheckResponse}
                                name="consigneeCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                id="consigneeCheckResponse"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.consigneeCheckResponse &&
                                touched.consigneeCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.consigneeCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.consigneeCheck && touched.consigneeCheck ? (
                          <span className="form-error hide-error">
                            {errors.consigneeCheck}
                          </span>
                        ) : null}
                      </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControl className="radio-group question-group">
                        <FormLabel >
                          Did you check same day shipments at time of pickup to see
                          if any are short and/or have ADE's that could have caused
                          a misdelivery for your overage?{" "}
                          {['14'].map((id) => (
                          <ClickAwayListener key={id} onClickAway={() => handleTooltipClose(id)}>
                          <Tooltip
                            PopperProps={{
                            disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose(id)}
                            open={openTooltips[id] || false}
                            disableFocusListener 
                            disableTouchListener
                            title="Example of exceptions to search for AS/DS/NF/BNF/TS/ etc."
                          > 
                          <InfoIcon
                            className="info-icon"
                            onMouseEnter={() => handleTooltipOpen(id)}
		                        onMouseLeave={() => handleTooltipClose(id)}
                          />
                          </Tooltip>
                          </ClickAwayListener>
                          ))}
                          {" "}
                          <sup>&nbsp;</sup>
                        </FormLabel>
                        <Grid container spacing={2}>
                          <Grid item md={12} xs={12} className="float-div textarea">
                            <RadioGroup
                              row
                              aria-labelledby="od400Bill"
                              name="shipmentCheck"
                            >
                              <FormControlLabel
                                value="Y"
                                control={<Radio />}
                                label="Yes"
                                name="shipmentCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.shipmentCheck === "Y"}
                              />
                              <FormControlLabel
                                value="N"
                                control={<Radio />}
                                label="No"
                                name="shipmentCheck"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                checked={values.shipmentCheck === "N"}
                              />
                            </RadioGroup>
                            <FormGroup className="form-row question-row">
                              <TextField
                                multiline
                                rows={2}
                                value={values.shipmentCheckResponse}
                                name="shipmentCheckResponse"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={formFieldsDisabled}
                                id="shipmentCheckResponse"
                                placeholder="Describe Steps Taken"
                                inputProps={{ maxLength: 180 }}
                                variant="outlined"
                              />
                              {errors.shipmentCheckResponse &&
                                touched.shipmentCheckResponse ? (
                                <span className="form-error hide-error">
                                  {errors.shipmentCheckResponse}
                                </span>
                              ) : null}
                            </FormGroup>
                          </Grid>
                        </Grid>
                        {errors.shipmentCheck && touched.shipmentCheck ? (
                          <span className="form-error hide-error">{errors.shipmentCheck}</span>
                        ) : null}
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
            <div>
              <div className="hide-me" id="req">
                <div>
                  {values && !_.isEmpty(values) &&
                    <div style={{ width: '100%', fontFamily: 'calibri' }}>
                      <div
                        style={{
                          width: '100vw',
                          background: '#f5f7fb',
                          padding: '10px 10px 15px',
                          lineHeight: '40px',
                          fontSize: '18px',
                          fontWeight: 'bold',
                          marginBottom: '15px',
                          textTransform: 'uppercase',
                        }}
                      >
                        Overage Investigation &nbsp; &nbsp;
                        <span
                          style={{
                            background: '#f5f5f5',
                            border: '1px solid #d4d4d4',
                            padding: '5px 15px',
                            borderRadius: '20px',
                            fontSize: '14px',
                            fontWeight: 'bold',
                          }}
                        >
                          #{props?.osdNumber}
                        </span>
                      </div>
                      {/* NB Q1 Starts*/}
                      <div
                        style={{
                          width: 'calc(100vw - 40px)',
                          margin: '0 20px 20px',
                          display: 'inline-block',

                        }}
                      >
                        <div style={{ margin: '0 0 10px' }}>
                          Was OD400 created by the dock?
                        </div>
                        <div>
                          <div
                            style={{
                              width: '15vw',
                              display: 'inline-block',
                              verticalAlign: 'top',
                            }}
                          >
                            {values.od400Check === "Y" ? "Yes" : "No"}
                          </div>
                          <div
                            style={{
                              width: '75%',
                              margin: '0 0 0 0',
                              display: 'inline-block',

                              background: '#f5f7fb',
                              padding: '10px',
                              border: '1px solid #b5b6b9',
                            }}
                          >
                            {values.od400CheckResponse}
                          </div>
                        </div>
                      </div>
                      {props?.exceptionType === 'NB' &&
                        <>
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you utilize the markings off of the freight to find the PRO#
                              that may apply?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',
                                  verticalAlign: 'top',
                                }}
                              >
                                {values.markingsCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.markingsCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q1 Ends*/}

                          {/* NB Q2 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you view the trailer history?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.thCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.thCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q2 Ends*/}

                          {/* NB Q3 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              If freight was found on a manifest, did you search that manifest
                              for other shipments marked as short or undelivered?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.mfCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.mfCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q3 Ends*/}

                          {/* NB Q4 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you check the OS&D master reporting for descriptions of open
                              shortages and ADE's where the overage may apply?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.osAndDCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.osAndDCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q4 Ends*/}

                          {/* NB Q5 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you google search the Manufacturer, distributor and/or
                              product information? If so, did you use that information in
                              freight bill tracing and/or OS&D searches?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.googleCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.googleCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q5 Ends*/}

                          {/* NB Q6 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you check the unmanifested outbound and inbound freight
                              reports to see if overage may match a non-movement shipment?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.unmanifestedCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.unmanifestedCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q6 Ends*/}

                          {/* NB Q7 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you search undelivered outbound and inbound shipments by
                              shipper or account code that have not been delivered?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.undeliveredCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.undeliveredCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q7 Ends*/}

                          {/* NB Q8 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you use the customer delivery analysis report to check
                              shipments picked up or going to a specific account code during a
                              specific time frame?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.customerDeliveryCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.customerDeliveryCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q8 Ends*/}

                          {/* NB Q9 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you check your undelivered and inventory reports for any
                              shipments not yet delivered?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.undelInvCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.undelInvCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q9 Ends*/}

                          {/* NB Q10 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you send APB to ALLOSD (If the overage value is greater than
                              $1,000)?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.apbCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.apbCheckResponse}
                              </div>
                            </div>
                          </div>
                          {/* Q10 Ends*/}

                          {/* NB Q11 Starts*/}
                          <div
                            style={{
                              width: 'calc(100vw - 40px)',
                              margin: '0 20px 20px',
                              display: 'inline-block',

                            }}
                          >
                            <div style={{ margin: '0 0 10px' }}>
                              Did you monitor all ALL OSD e-mail for possible match?
                            </div>

                            <div>
                              <div
                                style={{
                                  width: '15vw',
                                  display: 'inline-block',

                                  verticalAlign: 'top',
                                }}
                              >
                                {values.emailCheck === "Y" ? "Yes" : "No"}
                              </div>
                              <div
                                style={{
                                  width: '75%',
                                  margin: '0 0 0 0',
                                  display: 'inline-block',

                                  background: '#f5f7fb',
                                  padding: '10px',
                                  border: '1px solid #b5b6b9',
                                }}
                              >
                                {values.emailCheckResponse}
                              </div>
                            </div>
                          </div>
                        </>
                      }
                      {/* Q11 Ends*/}

                      {/* OV Q1 Starts*/}
                      {props?.exceptionType === 'OV' && <>
                        <div
                          style={{
                            width: 'calc(100vw - 40px)',
                            margin: '0 20px 20px',
                            display: 'inline-block',

                          }}
                        >
                          <div style={{ margin: '0 0 10px' }}>
                            Do you check the bill of lading for a billing error?
                          </div>

                          <div>
                            <div
                              style={{
                                width: '15vw',
                                display: 'inline-block',

                                verticalAlign: 'top',
                              }}
                            >
                              {values.billOfLadingCheck === "Y" ? "Yes" : "No"}
                            </div>
                            <div
                              style={{
                                width: '75%',
                                margin: '0 0 0 0',
                                display: 'inline-block',

                                background: '#f5f7fb',
                                padding: '10px',
                                border: '1px solid #b5b6b9',
                              }}
                            >
                              {values.billOfLadingCheckResponse}
                            </div>
                          </div>
                        </div>
                        {/* Q1 Ends*/}

                        {/* OV Q2 Starts*/}
                        <div
                          style={{
                            width: 'calc(100vw - 40px)',
                            margin: '0 20px 20px',
                            display: 'inline-block',

                          }}
                        >
                          <div style={{ margin: '0 0 10px' }}>
                            Did you check the DR to confirm what the consignee signed for?
                          </div>

                          <div>
                            <div
                              style={{
                                width: '15vw',
                                display: 'inline-block',

                                verticalAlign: 'top',
                              }}
                            >
                              {values.drCheck === "Y" ? "Yes" : "No"}
                            </div>
                            <div
                              style={{
                                width: '75%',
                                margin: '0 0 0 0',
                                display: 'inline-block',

                                background: '#f5f7fb',
                                padding: '10px',
                                border: '1px solid #b5b6b9',
                              }}
                            >
                              {values.drCheckResponse}
                            </div>
                          </div>
                        </div>
                        {/* Q2 Ends*/}

                        {/* OV Q3 Starts*/}
                        <div
                          style={{
                            width: 'calc(100vw - 40px)',
                            margin: '0 20px 20px',
                            display: 'inline-block',

                          }}
                        >
                          <div style={{ margin: '0 0 10px' }}>
                            If a packing slip was found, did you check the packing slip to
                            determine what the shipment consisted of?
                          </div>

                          <div>
                            <div
                              style={{
                                width: '15vw',
                                display: 'inline-block',

                                verticalAlign: 'top',
                              }}
                            >
                              {values.psCheck === "Y" ? "Yes" : "No"}
                            </div>
                            <div
                              style={{
                                width: '75%',
                                margin: '0 0 0 0',
                                display: 'inline-block',

                                background: '#f5f7fb',
                                padding: '10px',
                                border: '1px solid #b5b6b9',
                              }}
                            >
                              {values.psCheckResponse}
                            </div>
                          </div>
                        </div>
                        {/* Q3 Ends*/}

                        {/* OV Q4 Starts*/}
                        <div
                          style={{
                            width: 'calc(100vw - 40px)',
                            margin: '0 20px 20px',
                            display: 'inline-block',

                          }}
                        >
                          <div style={{ margin: '0 0 10px' }}>
                            Did you reach out to the consignee to verify what they received
                            on their delivery?
                          </div>

                          <div>
                            <div
                              style={{
                                width: '15vw',
                                display: 'inline-block',

                                verticalAlign: 'top',
                              }}
                            >
                              {values.consigneeCheck === "Y" ? "Yes" : "No"}
                            </div>
                            <div
                              style={{
                                width: '75%',
                                margin: '0 0 0 0',
                                display: 'inline-block',

                                background: '#f5f7fb',
                                padding: '10px',
                                border: '1px solid #b5b6b9',
                              }}
                            >
                              {values.consigneeCheckResponse}
                            </div>
                          </div>
                        </div>
                        {/* Q4 Ends*/}

                        {/* OV Q5 Starts*/}
                        <div
                          style={{
                            width: 'calc(100vw - 40px)',
                            margin: '0 20px 20px',
                            display: 'inline-block',

                          }}
                        >
                          <div style={{ margin: '0 0 10px' }}>
                            Did you check same day shipments at time of pickup to see if any
                            are short and/or have ADE's that could have caused a misdelivery
                            for your overage?
                          </div>

                          <div>
                            <div
                              style={{
                                width: '15vw',
                                display: 'inline-block',

                                verticalAlign: 'top',
                              }}
                            >
                              {values.shipmentCheck === "Y" ? "Yes" : "No"}
                            </div>
                            <div
                              style={{
                                width: '75%',
                                margin: '0 0 0 0',
                                display: 'inline-block',

                                background: '#f5f7fb',
                                padding: '10px',
                                border: '1px solid #b5b6b9',
                              }}
                            >
                              {values.shipmentCheckResponse}
                            </div>
                          </div>
                        </div>
                      </>}
                      {/* Q5 Ends*/}
                    </div>
                  }
                </div>
              </div>
            </div>

            {
              !(status?.toLowerCase()?.includes('complete') || _.isEmpty(errors)) && (
                <div className="font-weight">
                   Note: <i>To complete & image your investigation, please answer all investigation questions (Radio buttons and Text boxes).</i>
                </div>
              )
            }
             
             {/* <Snackbar
              className="snackbar-error warning"
              anchorOrigin={{ vertical, horizontal }}
              onClose={handleClose}
              open={!(status?.toLowerCase()?.includes('complete') || _.isEmpty(errors))}
              message="Note: To complete & image your investigation, please answer all investigation questions (Radio buttons and Text boxes)."
              key={vertical + horizontal}
            /> */}
            <div className="btn-group-submit">
              {formFieldsDisabled && !status?.toLowerCase()?.includes('complete') && (
                <>
                  <Button
                    onClick={enableEditing}
                    variant="contained"
                    className="assign mr-0 ml"
                    type="button"
                  >
                    Edit
                  </Button>
                  {_.isEmpty(errors) &&
                    <Button
                      variant="contained"
                      className="entry ml"
                      type="submit"
                    >
                      Complete & Image
                    </Button>
                  }
                </>
              )}
              {!formFieldsDisabled && (
                <>
                  <Button
                    onClick={() => reset(props?.isEditMode || isEditMode ? EFormAction.edit : EFormAction.create)}
                    variant="contained"
                    className="clear-btn mr-0 ml"
                    type="button"
                  >
                    {props?.isEditMode || isEditMode ? "Cancel" : "Clear"}
                  </Button>
                  <Button
                    type="button"
                    onClick={(e) => {
                      saveOrSubmit("save");
                    }}
                    variant="contained"
                    className="entry ml"
                  >
                    Save
                  </Button>
                </>
              )}
            </div>
          </AccordionDetails>
        </form>
        <Loader pageLoader={pageLoading}></Loader>
        <Snackbar
            className="snackbar-error"
            anchorOrigin={{ vertical, horizontal }}
            open={true}
            onClose={handleClose}
            message="Note: If you located a shipment belonging to a pro that has already delivered clear, send an e-mail to the OS&D clerk at the terminal to verify order before sending freight to the DT."
            key={vertical + horizontal}
          />
      </div>
  );
};

export default InvestigationForm;
