import React from 'react';
import { useOktaAuth } from "@okta/okta-react"
import { useSelector } from 'react-redux';
import AppState from '../../AppState';
const Loading: React.FC = () => {

  const { oktaAuth, authState } = useOktaAuth();
  const isLoggedIn: boolean = useSelector(
    (state: AppState) => state.isLoggedIn
  );
  
  console.log(
    "Inside Loader 1, authStated : ",
    authState
  );
  console.log(
    "Inside Loader 2, oktaAuth : ",
    oktaAuth
  );
  console.log(
    "Inside Loader 3, isLoggedIn :  ",
    isLoggedIn
  );
  return (
    <div id="loading-icon">
      <div className="barcode-img"></div>
    </div>
  );
};

export default Loading;