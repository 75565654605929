/**
 * The list of all available actions we can dispatch in our app.
 */
enum ACTION_TYPE {
  RESET_APP_STATE = "RESET_APP_STATE",
  LOG_USER_IN = "LOG_USER_IN",
  IS_PRIVILEGED_USER = "IS_PRIVILEGED_USER",
  USER_PERMISSION = "USER_PERMISSION",
  SERVRT_STATUS = "SERVRT_STATUS",
  TAB_POSITION = "TAB_POSITION",
  TERMINAL_SELECTION = "TERMINAL_SELECTION",
  LOADER_STATUS = "LOADER_STATUS",
  TERMINAL_MASTER_STATE = "TERMINAL_MASTER_STATE",
  OVERAGE_LIST_FILTER_FORM_STATE = "OVERAGE_LIST_FILTER_FORM_STATE",
  MY_ASSIGNMENT_LIST_FILTER_FORM_STATE = "MY_ASSIGNMENT_LIST_FILTER_FORM_STATE",
  SHORTAGES_LIST_FILTER_FORM_STATE = "SHORTAGES_LIST_FILTER_FORM_STATE",
  OVERAGE_LIST_FILTER_DATE_STATE = "OVERAGE_LIST_FILTER_DATE_STATE",
  OVERAGE_LIST_FILTER_EXCEPTION_CODE = "OVERAGE_LIST_FILTER_EXCEPTION_CODE",
  OVERAGE_LIST_ALL_EXCEPTION_CODE = "OVERAGE_LIST_ALL_EXCEPTION_CODE",
  OVERAGE_LIST_FILTER_COMMODITY_TYPE = "OVERAGE_LIST_FILTER_COMMODITY_TYPE",
  OVERAGE_LIST_FILTER_VALUE = "OVERAGE_LIST_FILTER_VALUE",
  OVERAGE_LIST_FILTER_STATUS = "OVERAGE_LIST_FILTER_STATUS",
  SHORTAGES_STATE_MASTER = "SHORTAGES_STATE_MASTER",
  NOTIFICATION_STATE = "NOTIFICATION_STATE",
  SHORTAGES_PAGE_STATE = "SHORTAGES_PAGE_STATE",
  OVERAGE_DATA_TABLE_PAGE_STATE = "OVERAGE_DATA_TABLE_PAGE_STATE",
  OVERAGE_DATA_TABLE_SORT_STATE = "OVERAGE_DATA_TABLE_SORT_STATE",
  SHORTAGES_SORT_STATE = "SHORTAGES_SORT_STATE",
  SHORTAGES_LIST_FILTER_EXCEPTION_CODE = "SHORTAGES_LIST_FILTER_EXCEPTION_CODE",
  PACKAGE_OPTION_MASTER = "PACKAGE_OPTION_MASTER",
  OVERAGE_ENTRY_FORM_CHANGED = "OVERAGE_ENTRY_FORM_CHANGED",
  MY_ASSIGNMENT_DATA_TABLE_SORT_STATE = "MY_ASSIGNMENT_DATA_TABLE_SORT_STATE",
  MY_ASSIGNMENT_DATA_TABLE_PAGE_STATE = "MY_ASSIGNMENT_DATA_TABLE_PAGE_STATE",
  DOC_TYPE_STATE = "DOC_TYPE_STATE",
}

export default ACTION_TYPE;
