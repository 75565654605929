import Button from "@mui/material/Button";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import InfoIcon from "@mui/icons-material/Info";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import {
  InitAllShortageFormValue,
  ShortageEntryNBSchema,
} from "../../../schema/dsException";
import { commodityTypeMasterApi } from "./../../../API/getCommodityTypeMaster";
import {
  IFilterDropdown,
  saveFilterCommodityTypeData,
  savePackageOptionMaster,
} from "./../../../AppState/overagesFilterFormActions";
import AppState from "./../../../AppState";
import { getFilterDropdowns } from "./../../../API/getFilterDropdowns";
import CustomDialog from "./../../../components/confirmationPopup/confirmationPopup";
import Loader from "./../../../components/loader/loader";
import { PopoutNotification } from "./../../../AppState/popoutNotification";
import NOTIFICATION_TYPE from "./../../../AppState/NotificationType";
import { AppURLs, EUploadKey } from "./../../../constants/app";
import { DateHelper } from "./../../../helpers/date.helper";
import {
  CompareObjects,
  HandleKeyDown,
  HandleNumberMaxLength,
} from "./../../../helpers/utils.helper";
import _ from "lodash";
import { IDeliveredShort } from "../../../models/shortage";
import { isFormValueChanged } from "../../../AppState/isFormValueChanged";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import UploadDocument from "../../../components/uploadDocument/uploadDocument";
import { EShortageInvestigationStatus } from "../../../constants/shortage";
import { GetDeliveredShortAPI } from "../../../API/getDeliveredShort";
import DsInvestigation from "./dsInvestigation";
import { UpdateDSEntryAPI } from "../../../API/updateDSEntry";

interface TooltipState {
  [key: string]: boolean;
}

function DeliveredShortException() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { osdNumber } = useParams();

  const formValueChanged = useSelector(
    (state: AppState) => state.isFormValueChanged
  );

  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  const [formFieldsDisabled, setFormFieldsDisabled] = React.useState<boolean>(
    !!osdNumber
  );

  const [osdDetails, setOsdDetails] = React.useState<IDeliveredShort>();
  const [updatedDateTime, setUpdatedDateTime] = React.useState<string>("");
  const [investigationStatus, setInvestigationStatus] =
    React.useState<number>();
  const [editFormValueChanged, setEditFormValueChanged] =
    React.useState<boolean>(false);
  const [inputValues, setInputValues] = React.useState({
    cInputValue: undefined,
  });

  const updateInputValue = (key: string, value: string | undefined) => {
    setInputValues((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
 
  const [autocompleteValues, setAutocompleteValues] = React.useState<any>({
    cType: undefined,
  });

  const updateAutocompleteValue = (key: string, value: any) => {
    setAutocompleteValues((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleAutoCompleteOnChange = (value: any) => {
    if (value) {
      updateAutocompleteValue("cType", value);
      setFieldValue("commodity", value?.code);
    }
  };

  const onAutoInputChange = (value: string) => {
    if (
      value === "undefined" ||
      (["commodity"].indexOf(document?.activeElement?.id || "") === -1 &&
        !value)
    )
      return;
    updateInputValue("cInputValue", value);
  };

  const commodityCodeOptions = useSelector(
    (state: AppState) => state.filterCommodityTypeData
  );
  const packageCodeOptions = useSelector(
    (state: AppState) => state.packageOptionMasterData
  );
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState(false); // State to manage dialog open/close
  // get master from API for commodity code and package code
  const [expanded, setExpanded] = React.useState<string | false>("panel1"); // handle expand page using "expanded" variable

  const selectStyle = {
    fontSize: "13px",
  };

  // set Initial value of All Shortage entry form
  const [isEditFormValuePatched, setIsEditFormValuePatched] =
    React.useState<string>("");
  const initValue: any = InitAllShortageFormValue;

  const [initialFormValue, setInitialFormValue] =
    React.useState<any>(initValue);

  // Initialize All shortage entry form using formik and pass validation schema and initial values
  // handle all form event and submit form using formik

  const {
    values,
    errors,
    handleChange,
    handleBlur,
    touched,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
  } = useFormik({
    validationSchema: ShortageEntryNBSchema,
    validateOnChange: false,
    initialValues: initValue,

    // Submit the form if all form fields are validated
    onSubmit: (values) => {
      if (!editFormValueChanged && osdNumber) {
        return;
      }
      setPageLoading(true);
      const reqInput = {
        ...values,
        weight: parseInt(values.weight),
        pieces: parseInt(values.pieces),
        commodity: values.commodity?.trim(),
      };

      const handleResponse = (response: IDeliveredShort) => {
        setPageLoading(false);
        setIsSubmitted(true);
        setExpanded("panel2");
        setFormFieldsDisabled(true);
        viewAllShortageEntry(response.osdNumber || osdNumber);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.SUCCESS,
            `OS&D # ${osdNumber} has been updated successfully`,
            Date.now().toString()
          )
        );
      };

      const handleError = (err: any) => {
        setPageLoading(false);
        dispatch(
          PopoutNotification(
            NOTIFICATION_TYPE.ERROR,
            err || `Delivered Shortage entry updating failed`,
            Date.now().toString()
          )
        );
      };
      UpdateDSEntryAPI(reqInput, osdNumber)
        .then(handleResponse)
        .catch(handleError);
    },
  });

  const handleError = (error: any) => console.error(error);

  const commodityMaster = () => {
    commodityTypeMasterApi()
      .then((results) => {
        const sortedData = results.data
          .map((a: IFilterDropdown) => ({ ...a, code: a.code?.trim() }))
          .sort(
            (a: IFilterDropdown, b: IFilterDropdown) =>
              parseInt(a.code!) - parseInt(b.code!)
          );

        if (values?.commodity) {
          const selectedValue = sortedData.find(
            (d: IFilterDropdown) => d.code === values.commodity
          );
          updateAutocompleteValue("cType", selectedValue);
        }

        dispatch(saveFilterCommodityTypeData(sortedData));
      })
      .catch(handleError);
  };

  // get Commodity and package code option master
  const getMasters = (ParamString: string) => {
    getFilterDropdowns({ dataType: ParamString })
      .then((results: IFilterDropdown[]) => {
        dispatch(savePackageOptionMaster(results));
      })
      .catch(handleError);
  };

  // Handle accordion expand
  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (
        ["panel2", "panel3"].includes(panel) &&
        isExpanded &&
        !isSubmitted &&
        !osdNumber
      )
        return;
      if (osdNumber) {
        setFormFieldsDisabled(true);
        reset();
      }
      setExpanded(isExpanded ? panel : false);
    };

  const enableEditing = () => {
    setFormFieldsDisabled(false);
  };

  // set form value on exception type changed
  const reset = () => {
    resetForm();
    patchEditFormValues(initialFormValue);
  };

  // handle confirmation pop while user want back according button
  const handleDialogOpen = () => {
    if (!formValueChanged) navigate(AppURLs.shortages);
    setDialogOpen(true);
  };

  // Close the confirmation modal pop and navigate user to back All Shortage listing page if user confirmed
  const handleDialogClose = (flag?: string) => {
    setDialogOpen(false);
    if (flag === "yes") {
      navigate(AppURLs.shortages);
    }
  };

  const patchEditFormValues = (allShortage: any) => {
    const trimOrDefault = (value: any) =>
      typeof value === "string" ? value.trim() || "" : value;

    // Set form field values
    Object.keys(InitAllShortageFormValue).forEach((field) => {
      setFieldValue(field, trimOrDefault(allShortage[field]));
    });

    // Handle commodity code options
    if (commodityCodeOptions?.length && allShortage?.commodity) {
      const selectedOption = commodityCodeOptions.find(
        (option: any) => option?.code?.trim() === allShortage?.commodity
      );
      if (selectedOption) {
        updateAutocompleteValue("cType", selectedOption);
      }
      updateInputValue("cInputValue", allShortage.commodity);
    }

    // Indicate that form values have been patched
    setIsEditFormValuePatched(Date.now().toString());
  };
  const viewAllShortageEntry = (osdId?: number | string) => {
    if (osdNumber || osdNumber || osdId) {
      // get AS entry and set form fields
      setPageLoading(true);
      GetDeliveredShortAPI(osdNumber || osdNumber || osdId!)
        .then((allShortage: IDeliveredShort) => {
          const { createdDate, lastUpdatedDate, createdTime, lastUpdatedTime } =
            allShortage;

          const date =
            createdDate && createdDate! > lastUpdatedDate!
              ? createdDate
              : lastUpdatedDate;
          const time =
            createdDate &&
            createdTime &&
            (createdDate! > lastUpdatedDate! ||
              (createdDate === lastUpdatedDate &&
                createdTime! > lastUpdatedTime!))
              ? createdTime
              : lastUpdatedTime;

          if (date && time) {
            setUpdatedDateTime(DateHelper.mergerAS400DateTime(date, time));
          }
          setOsdDetails(allShortage);
          setInvestigationStatus(
            allShortage?.investigationStatusNumber ||
              EShortageInvestigationStatus.exceptionEntry
          );
          patchEditFormValues(allShortage);
          setPageLoading(false);
        })
        .catch((err: any) => {
          console.error(err);
          setPageLoading(false);
        });
    }
  };

  // Declare a new state variable for tooltips with a default empty object
  const [openTooltips, setOpenTooltips] = useState<TooltipState>({});

  // Function to handle tooltip close
  const handleTooltipClose = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: false }));
  };

  // Function to handle tooltip open
  const handleTooltipOpen = (id: string) => {
    setOpenTooltips((prev) => ({ ...prev, [id]: true }));
  };

  useEffect(() => {
    if (
      commodityCodeOptions &&
      commodityCodeOptions.length > 0 &&
      osdDetails?.commodity
    ) {
      updateAutocompleteValue(
        "cType",
        commodityCodeOptions.find(
          (d: any) => d?.code?.trim() === osdDetails?.commodity
        )
      );
      updateInputValue("cInputValue", osdDetails?.commodity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commodityCodeOptions, osdDetails]);

  useEffect(() => {
    if (osdNumber && isEditFormValuePatched) {
      setInitialFormValue(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditFormValuePatched]);

  useEffect(() => {
    setEditFormValueChanged(!CompareObjects(values, initialFormValue));
    dispatch(isFormValueChanged(!CompareObjects(values, initialFormValue)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, initialFormValue]);

  useEffect(() => {
    dispatch(isFormValueChanged(false));
    if (osdNumber) {
      viewAllShortageEntry();
    }
    if (commodityCodeOptions === null || commodityCodeOptions.length === 0) {
      commodityMaster();
    }
    if (packageCodeOptions === null || packageCodeOptions.length === 0) {
      getMasters("packageCode");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmitting) return;
    if (Object.keys(errors).length > 0) {
      document.getElementsByName(Object.keys(errors)[0])[0].focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  return (
    <div className="bg-layout overage-entry">
      {/* header */}
      <div className="header-section">
        <div className="back-section">
          <div className="back" onClick={handleDialogOpen}>
            <ArrowLeftIcon />
          </div>
          <div className="page-hader-content">
            <div className="page-header">Delivered Shortage Investigation</div>
            {(osdNumber || isSubmitted) && (
              <div className="page-subtitle">
                Last update: &nbsp;
                {updatedDateTime ? updatedDateTime : "NA"}
              </div>
            )}
          </div>
          {!!osdNumber && <span className="badge">#{osdNumber}</span>}
        </div>
        <div className="edit-section"></div>
      </div>
      <CustomDialog open={dialogOpen} onClose={handleDialogClose} />

      {/* body */}
      <div className="content-area">
        <Accordion
          className="accordion"
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography
              className={
                isSubmitted || osdNumber
                  ? "queue-check queue-check-gc"
                  : "queue-check"
              }
            >
              1
            </Typography>
            <Typography className="accordion-header">
              Delivered Short Exception
            </Typography>
          </AccordionSummary>
          <form action="" onSubmit={handleSubmit}>
            <AccordionDetails className="accordion-container">
              <div id="overageEntry">
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="type">
                        Type <sup>&nbsp;</sup>
                      </InputLabel>
                      <TextField
                        
                        value={osdDetails?.type}
                        name="type"
                        disabled
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} >
                        Reporting Terminal <sup>&nbsp;</sup>
                      </InputLabel>
                      <TextField
                        
                        value={osdDetails?.reporting}
                        name="reporting"
                        disabled
                        variant="outlined"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <div>
                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="checkDigitLabel"
                        >
                          PRO# <sup>*</sup>
                        </InputLabel>
                        <TextField
                          id="checkDigit"
                          variant="outlined"
                          disabled
                          value={osdDetails?.proNumber}
                          name="proNumber"
                          type="number"
                        />
                      </FormControl>
                      {errors.proNumber && touched.proNumber ? (
                        <span className="form-error">
                          {errors.proNumber.toString()}
                        </span>
                      ) : null}
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="originLabel"
                        >
                          Origin Terminal <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id="origin"
                          value={osdDetails?.origin}
                          name="origin"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Destination Terminal <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.destination}
                          name="destination"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="lastTrailerLabel"
                        >
                          Last Trailer Number <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          id="lastTrailer"
                          value={osdDetails?.trailer}
                          name="origin"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Last Manifest Number <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.lastManifest}
                          name="lastManifest"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Shipper Account # <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.shipperAccount}
                          name="shipperAccount"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Shipper Name <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.shipper}
                          name="shipper"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Shipper Address <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.shipperAddress}
                          name="shipperAddress"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipperZipLabel"
                        >
                          Shipper Zip
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.shipperZip}
                          name="shipperZip"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipperCityLabel"
                        >
                          Shipper City
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.shipperCity}
                          name="shipperCity"
                          disabled
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="shipperStateLabel"
                        >
                          Shipper State
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.shipperState}
                          name="shipperState"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Consignee Account # <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.consigneeAccount}
                          name="consigneeAccount"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Consignee Name <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.consignee}
                          name="consignee"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel style={selectStyle} shrink={true} >
                          Consignee Address <sup>&nbsp;</sup>
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.consigneeAddress}
                          name="consigneeAddress"
                          disabled
                          variant="outlined"
                        />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} className="filter-margin">
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeZipLabel"
                        >
                          Consignee Zip
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.consigneeZip}
                          name="consigneeZip"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeCityLabel"
                        >
                          Consignee City
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.consigneeCity}
                          name="consigneeCity"
                          disabled
                        />
                      </FormControl>
                    </Grid>

                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth size="small">
                        <InputLabel
                          style={selectStyle}
                          shrink={true}
                          id="consigneeStateLabel"
                        >
                          Consignee State
                        </InputLabel>
                        <TextField
                          
                          value={osdDetails?.consigneeState}
                          name="consigneeState"
                          disabled
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={3} xs={12}>
                    <FormControl
                      style={{ minWidth: 120 }}
                      fullWidth
                      size="small"
                    >
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="commodityLabel"
                      >
                        Commodity <sup>*</sup>
                      </InputLabel>
                      <Autocomplete
                        disabled={formFieldsDisabled}
                        className="custom-autocomplete"
                        noOptionsText="Records not found"
                        disablePortal
                        getOptionLabel={(option) =>
                          `${option.code} -  ${option.displayValue}`
                        }
                        value={autocompleteValues.cType}
                        key={autocompleteValues.cType?.code}
                        inputValue={inputValues.cInputValue}
                        onClose={() => {
                          updateInputValue("cInputValue", "");
                        }}
                        onInputChange={(e, v) => onAutoInputChange(v)}
                        forcePopupIcon={false}
                        options={commodityCodeOptions}
                        onChange={(e, v) => handleAutoCompleteOnChange(v)}
                        disableClearable={true}
                        renderOption={(props, option) => (
                          <li
                            {...props}
                            title={`${option.code?.trim()} - ${option.displayValue.trim()}`}
                          >
                            {option.code?.trim()} - {option.displayValue.trim()}
                          </li>
                        )}
                        filterOptions={(options, { inputValue }) =>
                          options.filter((option) =>
                            option.displayValue
                              .concat(" ", option?.code ?? "")
                              ?.toLowerCase()
                              .includes(inputValue?.toLowerCase())
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            name="commodity"
                            placeholder="Enter & Select Commodity"
                            onBlur={handleBlur}
                            error={!!(errors.commodity && touched.commodity)}
                            {...params}
                            variant="outlined"
                          />
                        )}
                        renderTags={(value) => (
                          <div
                            className="auto-complete-selected-container"
                            title={value.join(", ")}
                          >
                            {value.join(", ")}
                          </div>
                        )}
                      />
                    </FormControl>
                    {errors.commodity && touched.commodity ? (
                      <span className="form-error">
                        {errors.commodity.toString()}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="packageLabel"
                      >
                        Package Type <sup>*</sup>
                      </InputLabel>
                      <Select
                        variant="outlined"
                        id="package"
                        className="select"
                        fullWidth
                        onChange={handleChange}
                        error={!!(errors.package && touched.package)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="package"
                        value={values?.package}
                        style={selectStyle}
                        displayEmpty
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>Select Package Type</em>;
                          }
                          return selected;
                        }}
                        IconComponent={() => (
                          <div className="dropdown-icon-group">
                            <ArrowDropUpIcon className="icon-style" />{" "}
                            <ArrowDropDownIcon className="icon-style" />{" "}
                          </div>
                        )}
                      >
                        {packageCodeOptions.map((obj: any) => (
                          <MenuItem
                            style={selectStyle}
                            key={obj?.actualValue}
                            value={obj?.actualValue}
                          >
                            {obj?.actualValue}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.package && touched.package ? (
                      <span className="form-error">
                        {errors.package.toString()}
                      </span>
                    ) : null}
                  </Grid>

                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="piecesLabel">
                        Total Pieces <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        placeholder="Enter Pieces"
                        type="number"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.pieces && touched.pieces)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="pieces"
                        value={values.pieces}
                        onKeyDown={HandleKeyDown}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          HandleNumberMaxLength(e, 5);
                        }}
                      />
                    </FormControl>
                    {errors.pieces && touched.pieces ? (
                      <span className="form-error">
                        {errors.pieces.toString()}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel style={selectStyle} shrink={true} id="weightLabel">
                        Weight <sup>*</sup>
                      </InputLabel>
                      <TextField
                        type="number"
                        id="weight"
                        placeholder="Enter Weight"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.weight && touched.weight)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="weight"
                        value={values.weight}
                        onKeyDown={HandleKeyDown}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          HandleNumberMaxLength(e, 7);
                        }}
                      />
                    </FormControl>
                    {errors.weight && touched.weight ? (
                      <span className="form-error">
                        {errors.weight.toString()}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="consigneeAddressLabel"
                      >
                        Does the shipment have (Item#, Carton#, Model#, Serial#){" "}
                        <sup>*</sup>
                      </InputLabel>
                      <TextField
                        inputProps={{ maxLength: 56 }}
                        id="outlined-basic"
                        placeholder="Enter Item#, Carton#, Model#, Serial# with comma separated"
                        variant="outlined"
                        onChange={handleChange}
                        error={
                          !!(
                            errors.itemCartonModelSerialNumber &&
                            touched.itemCartonModelSerialNumber
                          )
                        }
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="itemCartonModelSerialNumber"
                        value={values.itemCartonModelSerialNumber}
                      />
                    </FormControl>
                    {errors.itemCartonModelSerialNumber &&
                    touched.itemCartonModelSerialNumber ? (
                      <span className="form-error">
                        {errors.itemCartonModelSerialNumber.toString()}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormControl className="radio-group value-range">
                      <FormLabel id="valueRangeLabel">
                        Value Range <sup>*</sup>
                      </FormLabel>

                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          className="float-div form-content-start"
                        >
                          <FormGroup className="shift-left">
                            <RadioGroup
                              row
                              aria-labelledby="valueRange"
                              name="valueRange"
                              value={osdDetails?.valueRange}
                            >
                              <FormControlLabel
                                value="1"
                                disabled
                                control={<Radio />}
                                label="$0 - $250.00"
                                name="valueRange"
                                checked={osdDetails?.valueRange === "1"}
                              />
                              <FormControlLabel
                                value="2"
                                disabled
                                control={<Radio />}
                                label="$250.00 - $1,000.00"
                                name="valueRange"
                                checked={osdDetails?.valueRange === "2"}
                              />
                              <FormControlLabel
                                disabled
                                value="3"
                                control={<Radio />}
                                label="Greater than $1,000.00"
                                name="valueRange"
                                checked={osdDetails?.valueRange === "3"}
                              />
                              {["day1"].map((id) => (
                                <ClickAwayListener
                                  key={id}
                                  onClickAway={() => handleTooltipClose(id)}
                                >
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    onClose={() => handleTooltipClose(id)}
                                    open={openTooltips[id] || false}
                                    disableFocusListener
                                    disableTouchListener
                                    title="Round the value up to the nearest dollar amount"
                                  >
                                    <InfoIcon
                                      className="info-icon round-info"
                                      onMouseEnter={() => handleTooltipOpen(id)}
                                      onMouseLeave={() =>
                                        handleTooltipClose(id)
                                      }
                                    />
                                  </Tooltip>
                                </ClickAwayListener>
                              ))}
                            </RadioGroup>
                          </FormGroup>

                          {osdDetails?.valueRange === "3" && (
                            <FormGroup className="no-wrap">
                              <FormGroup className="form-row shift-left">
                                <TextField
                                  className="justify-content disabled"
                                  
                                  value="$"
                                  variant="outlined"
                                  disabled
                                />
                                <TextField
                                  type="number"
                                  className="justify-content"
                                  
                                  placeholder="Enter Amount"
                                  variant="outlined"
                                  disabled
                                  name="value1k"
                                  value={osdDetails?.value1k}
                                />
                              </FormGroup>
                              {errors.value1k && touched.value1k ? (
                                <span className="form-error">
                                  {errors.value1k.toString()}
                                </span>
                              ) : null}
                            </FormGroup>
                          )}
                        </Grid>
                        {errors.valueRange && touched.valueRange ? (
                          <span className="form-error radio-group-validation">
                            {errors.valueRange.toString()}
                          </span>
                        ) : null}
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="consigneeAddressLabel"
                      >
                        Number of Shrink Wrapped Pallets <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        disabled
                        name="shrinkWrappedPalletCount"
                        value={osdDetails?.shrinkWrappedPalletCount}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <FormControl className="radio-group value-range">
                      <FormLabel id="wasOriginalShrinkwrapIntactLabel">
                        Was the Original Shrinkwrap Intact? <sup>*</sup>
                      </FormLabel>

                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={12}
                          xs={12}
                          className="float-div form-content-start"
                        >
                          <FormGroup className="shift-left">
                            <RadioGroup
                              row
                              aria-labelledby="wasOriginalShrinkwrapIntact"
                              name="wasOriginalShrinkwrapIntact"
                              value={osdDetails?.wasOriginalShrinkwrapIntact}
                            >
                              <FormControlLabel
                                value="Y"
                                disabled
                                control={<Radio />}
                                label="Yes"
                                name="wasOriginalShrinkwrapIntact"
                                checked={
                                  osdDetails?.wasOriginalShrinkwrapIntact ===
                                  "Y"
                                }
                              />
                              <FormControlLabel
                                value="N"
                                disabled
                                control={<Radio />}
                                label="No"
                                name="wasOriginalShrinkwrapIntact"
                                checked={
                                  osdDetails?.wasOriginalShrinkwrapIntact ===
                                  "N"
                                }
                              />
                            </RadioGroup>
                          </FormGroup>
                        </Grid>
                      </Grid>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container spacing={2} className="filter-margin">
                  <Grid item md={12} xs={12}>
                    <FormControl fullWidth size="small">
                      <InputLabel
                        style={selectStyle}
                        shrink={true}
                        id="descriptionLabel"
                      >
                        Additional Details <sup>*</sup>
                      </InputLabel>
                      <TextField
                        id="description"
                        placeholder="Enter Additional Details"
                        variant="outlined"
                        onChange={handleChange}
                        error={!!(errors.description && touched.description)}
                        disabled={formFieldsDisabled}
                        onBlur={handleBlur}
                        name="description"
                        value={values.description}
                        inputProps={{ maxLength: 750 }}
                      />
                    </FormControl>
                    {errors.description && touched.description ? (
                      <span className="form-error">
                        {errors.description.toString()}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              </div>
              <div className="btn-group-submit">
                {!!osdNumber && !formFieldsDisabled && (
                  <>
                    <Button
                      type="button"
                      variant="contained"
                      className="clear-btn mr-0 ml"
                      onClick={() => reset()}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      className={
                        editFormValueChanged
                          ? "assign mr-0 ml"
                          : "disable-btn disable assign mr-0 ml"
                      }
                    >
                      Update
                    </Button>
                  </>
                )}
                {!osdNumber && !isSubmitted && (
                  <>
                    <Button
                      type="submit"
                      variant="contained"
                      className={
                        isSubmitted
                          ? "disable-btn disable entry ml"
                          : "entry ml"
                      }
                    >
                      {values.valueRange === "3"
                        ? "Submit & Send APB"
                        : "Submit"}
                    </Button>
                  </>
                )}
                {!!osdNumber &&
                  formFieldsDisabled &&
                  !(
                    investigationStatus ===
                    EShortageInvestigationStatus.complete
                  ) && (
                    <Button
                      // type="submit"
                      variant="contained"
                      className="assign mr-0 ml"
                      onClick={enableEditing}
                    >
                      Edit
                    </Button>
                  )}
              </div>
            </AccordionDetails>
          </form>
        </Accordion>

        <Accordion
          className="accordion"
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <Typography
              className={
                investigationStatus === EShortageInvestigationStatus.complete
                  ? "queue-check queue-check-gc"
                  : "queue-check"
              }
            >
              2
            </Typography>
            <Typography className="accordion-header">Delivered Short Investigation &nbsp;&nbsp;&nbsp;</Typography>
            <Typography className="header-notice-2 note-scroll">
              <div className="animate">Do not request camera check prior to reaching that stage of the investigation</div>
            </Typography>

          </AccordionSummary>

          <DsInvestigation
           osdNumber={typeof osdNumber === 'string' ? parseInt(osdNumber, 10) : osdNumber ?? 0}
           expanded={expanded}
           setInvestigationStatus={setInvestigationStatus} 
           exceptionType="someValue"/>

          
        </Accordion>
        <Accordion
          className="accordion"
          expanded={expanded === "panel3"}
          onChange={handleAccordionChange("panel3")}
        >
          <AccordionSummary
            className="header"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <Typography className="queue-check">3</Typography>
            <Typography className="accordion-header">
              Upload Documents
            </Typography>
          </AccordionSummary>
          <UploadDocument
            key={expanded || "upload-document"}
            osdNumber={parseInt(osdNumber!)}
            uploadKey={EUploadKey.pro}
          />
        </Accordion>
      </div>
      <Loader pageLoader={pageLoading}></Loader>
    </div>
  );
}

export default DeliveredShortException;
