import {
  CreateDynamicSchema,
  FieldConfig,
} from "./formik";

// Configuration array defining validation rules
const config: FieldConfig[] = [

  {
    name: "commodity",
    type: "string",
    required: true,
    message: "Commodity is required",
  },
  {
    name: "package",
    type: "string",
    required: true,
    message: "Package type is required",
  },
  {
    name: "pieces",
    type: "number",
    required: true,
    message: "Total Pieces are required",
  },
  {
    name: "weight",
    type: "number",
    required: true,
    message: "Weight is required",
  },
  {
    name: "itemCartonModelSerialNumber",
    type: "string",
    required: true,
    message:
      "Does the shipment have (Item#, Carton#, Model#, Serial#) is required",
    max: 56,
    maxMessage:
      "Item#, Carton#, Model#, Serial# must be less than or equal to 56 characters",
  },
  {
    name: "description",
    type: "string",
    required: true,
    message: "Additional Details is required",
    max: 750,
    maxMessage:
      "Additional Details must be less than or equal to 750 characters",
  },
];
// Function to create a Yup validation schema dynamically from the config array

// Exporting the dynamically created schema
export const ShortageEntryNBSchema = CreateDynamicSchema(config);

export const InitAllShortageFormValue = {
  itemCartonModelSerialNumber: "",
  weight: "",
  commodity: "",
  package: "",
  pieces: "",
  description: "",
};
