export const ConstantMessage: any = {
    serverError:'Something went wrong. Please try again later.',
    terminalAPIError :'There was an error with the Get Terminals API.',
    DashboardSummaryAPIError:'Error occurred while fetching Dashboard Summary  from API',
    validateOktaToken: 'Unable to validate Okta Token. Please try again.',
    overageList: 'Error occurred while fetching Overage list from API',
    exceptionAssign: 'Error occurred while assigning osd to user',
    exceptionDetails: 'Error occurred while fetching Exception details from API',
    proImage: 'Error occurred while fetching pro images from API',
    manifestDetails: 'Error occurred while fetching Manifest details from API',
    manifestProDetails: "Error occurred while fetching Manifest's PRO details from API",
    sendAPBMail: 'Error occurred while sending APB mail, please try again',
    masterProNumber: "Error occurred while fetching Master Pro numbers from API",
    masterOsdNumber: "Error occurred while fetching Master OSD numbers from API",
    commodityTypeMaster: "Error occurred while fetching Master Commodity from API",
    exceptionCodesMaster: "Error occurred while fetching Master Exception codes from API",
    checkPro: "Error occurred while fetching Check Digit pro details from API",
    createOverageEntry: "Error creating Overage Entry. Please try again.",
    createUpdateShortageInvestigation: "Error creating or updating shortage Investigation Entry. Please try again.",
    updateOverageEntry: "Error updating Overage Entry. Please try again.",
    updateASEntry: "Error updating All Shortage Entry. Please try again.",
    updateDSEntry: "Error updating delivered Short Entry. Please try again.",
    createShortageEntry: "Error creating Shortage Entry. Please try again.",
    OverageEntryDetailsAPIError: "Error occurred while fetching Overage Entry Details from API",
    aSEntryDetailsAPIError: "Error occurred while fetching All Shortage Entry Details from API",
    dSEntryDetailsAPIError: "Error occurred while fetching Delivered Short Entry Details from API",
    saveOrSubmitAPIError: "Error occurred while saving or submitting Investigation notes. Please try again.",
    getInvestigationAPIError: "Error occurred while fetching Investigation from API",
    InvestigationImageUploadAPIError: "Failed to upload Investigation Image. Please try again",
    locationMaster: "Error occurred while fetching zip Code Master records",
    DocTypeAPIError: "Error occurred while fetching Doc Types from API.",
    getShortagesAPIError: "Error occurred while fetching Shortages. Please try again.",
    PrintAPIError: "Error occurred while printing. Please try again.",
    logglyLogAPIError: "Error occurred while Log error in Loggly. Please try again.",
  };